import React from "react";
import { useNavigate } from "react-router-dom";
import aboutImage from "../images/dollar.png";
import "./About.css";

const About = () => {
  const navigate = useNavigate();
  return (
    <section className="about-section">
      <div className="about-image">
        <img src={aboutImage} alt="About" />
      </div>
      <div className="about-content">
        <h6>About</h6>
        <h1 className="aboutheading">Equity & Index</h1>
        <p>Research</p>
        <h1 className="aboutheading">Consulting</h1>
        <p>
          Welcome to Real Stock Experts , a Mumbai (India) based SEBI
          registered research analyst that has been providing reliable and
          insightful stock and index recommendations to its clients. We are
          dedicated to helping our clients create wealth through thoroughly
          researched investment decisions with cutting edge expertise. Team
          Real Stock Experts has repeatedly introduced various products and
          services that performed much better than major stock indices and
          rewarded investors handsomely. Each and every piece of advice is given
          after extensive and precise research in stock market movement.
        </p>
        <div className="about-buttons">
          <button onClick={() => navigate('/contact')} className="get-in-touch">Get In Touch</button>
          <button onClick={() => navigate('/service')} className="our-services">Our Services</button>
        </div>
      </div>
    </section>
  );
};

export default About;
