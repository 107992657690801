// Footer.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h2>About Company</h2>
          <p>We are SEBI registered as Research Analyst. Providing research recommendations to traders and investors of Indian equity market.</p>
          <p>Investor Charter RA</p>
        </div>
        <div className="footer-section">
          <h2>Our Services</h2>
          <div className="services-columns">
            <ul>
              <li ><a href='/services/classic-stock-future'>Platinum Stock Future</a></li>
              <li ><a href='/services/classic-stock-future'>PClassic Stock Future</a></li>
              <li ><a href='/services/btst-short-term-long-term'>PBTST/Short Term/Long Term</a></li>
              <li ><a href='/services/platinum-equity'>PPlatinum Equity</a></li>
              <li ><a href='/services/classic-equity'>PClassic Equity</a></li>
            </ul>
            <ul>
              <li  ><a href='/services/platinum-index-future'>PPlatinum Index Future</a></li>
              <li ><a href='/services/platinum-index-options'>PPlatinum Index Options</a></li>
              <li><a href='/services/classic-index-options'>PClassic Index Options</a></li>
              <li ><a href='/services/platinum-stock-option'>PPlatinum Stock Option</a> </li>
              <li ><a href='/services/classic-stock-option'>PClassic Stock Option</a></li>
            </ul>
          </div>
        </div>
        <div className="footer-section">
          <h2>Get In Touch</h2>
          <ul>
            <li>📞 (+91) 9510980382</li>
            <li>✉️ admin@realstockexperts.in</li>
            <li>🔗 <a href="realstockexpertsmumbai.com/">realstockexperts.in</a></li>
            <li>⏰ Office Hour: <strong>9AM - 6PM</strong></li>
            <li>🏢 Mumabi</li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2023 Real Stock Experts. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
