import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './BottomNav.css'; // Import the CSS file

const BottomNav = () => {
  return (
    <div className="bottom-nav">
      <a className="bottom-nav-item" href="tel:+919510980382">
        <FontAwesomeIcon icon={faPhone} className="icon" style={{ color: '#25D366' }} /> {/* Green for Phone */}
        <span className="label">Mobile</span>
      </a>
      <a className="bottom-nav-item" href="mailto:admin@realstockexperts.in">
        <FontAwesomeIcon icon={faEnvelope} className="icon" style={{ color: '#0078D4' }} /> {/* Blue for Email */}
        <span className="label">Email</span>
      </a>
      <a className="bottom-nav-item" href="https://wa.me/+919510980382">
        <FontAwesomeIcon icon={faWhatsapp} className="icon" style={{ color: '#25D366' }} /> {/* Green for WhatsApp */}
        <span className="label">WhatsApp</span>
      </a>
    </div>
  );
};

export default BottomNav;
