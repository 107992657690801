import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Button, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import banner2 from '../images/banner_4.jpg';
import banner3 from '../images/banner_5.jpg';
// import banner1 from '../images/banner_6.jpg';
import banner4 from '../images/finance.webp';
import banner5 from '../images/finance2.webp';
import './CustomCarousel.css';

const CustomCarousel = () => {
  const navigate = useNavigate();

  const slides = [
    {
      image: banner4,
      text: "WE'RE SEBI REGISTERED RESEARCH ANALYST"
    },
    {
      image: banner2,
      text: "PROVIDING TOP-NOTCH FINANCIAL SERVICES"
    },
    {
      image: banner3,
      text: "EXPERTS IN MARKET ANALYSIS"
    },

    {
      image: banner5,
      text: "EXPERTS IN MARKET ANALYSIS"
    },
  ];

  return (
    <Carousel interval={5000} pause={false} nextIcon={<FontAwesomeIcon icon={faChevronRight} />} prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={slide.image}
            alt={`Slide ${index + 1}`}
          />
          <Carousel.Caption className="custom-caption">
            <h1 className="display-4">{slide.text}</h1>
            <div className="d-flex button-group">
              <Button  onClick={() => window.open('https://wa.me/919510980382', '_blank')} variant="warning" className="mr-2 custom-button">Contact Us</Button>
              <Button onClick={() => navigate('/service')} variant="outline-light" className="custom-button">Our Services</Button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
