import React from 'react';
import './FloatingWhatsApp.css'; // Create a CSS file for styles
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon

const FloatingWhatsApp = () => {
  const openWhatsApp = () => {
    const phone = '+919510980382'; // Replace with your WhatsApp number
    const message = encodeURIComponent('Hello, I need assistance!');
    window.open(`https://wa.me/${phone}?text=${message}`, '_blank');
  };

  return (
    <div className="floating-whatsapp" onClick={openWhatsApp}>
      <FaWhatsapp size={30} />
    </div>
  );
};

export default FloatingWhatsApp;
