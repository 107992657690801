import React from 'react';
import './LetsDiscussSection.css'; // You'll need to style this component

function LetsDiscussSection() {
  return (
    <div className="lets-discuss-section">
      <div className="lets-discuss-content">
        <h2>Let's discuss about how we can help make your business better</h2>
      </div>
      <div className="consultancy-box">
        <p>Free Consultancy</p>
        <a href="https://wa.me/+919510980382">
        Get In Touch
        </a>
      </div>
    </div>
  );
}

export default LetsDiscussSection;
